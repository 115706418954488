import React, { useState } from 'react';
import { useFormik } from 'formik';
import classNames from 'classnames';
import PageWrapper from '../components/layouts/PageWrapper/PageWrapper';
import Button from '../components/ui/Button';
import { useAuth } from '../context/authContext';
import Input from '../components/form/Input';
import LogoTemplate from '../templates/layouts/Logo/Logo.template';
import FieldWrap from '../components/form/FieldWrap';
import Icon from '../components/icon/Icon';
import Validation from '../components/form/Validation';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Navigate, useNavigate, useNavigation } from 'react-router-dom';


type TValues = {
	username: string;
	password: string;
};

const LoginPage = () => {
	const { onLogin } = useAuth();
	const [passwordShowStatus, setPasswordShowStatus] = useState<boolean>(false);

	const navigate = useNavigate();

	const formik = useFormik({
		initialValues: {
			username: '',
			password: '',
		},
		validate: (values) => {
			const errors: Partial<TValues> = {};
			if (!values.username) {
				errors.username = 'Required';
			}
			if (!values.password) {
				errors.password = 'Required';
			}
			return errors;
		},
		onSubmit: async (values: TValues, { setFieldError }) => {
			try {
				onLogin(values.username, values.password);
			} catch (error) {
				setFieldError('password', 'Invalid username or password');
			}
		},
	});

	return (
		<PageWrapper isProtectedRoute={false} className='bg-white dark:bg-inherit' name='Sign In'>
			<ToastContainer
				position='bottom-right'
				autoClose={3000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
			<div className='container mx-auto flex h-full items-center justify-center'>
				<div className='flex max-w-sm flex-col gap-8'>
					<div>
						<LogoTemplate className='h-12' />
					</div>
					<div>
						<span className='text-4xl font-semibold'>Sign in</span>
					</div>
					<div className='border border-zinc-500/25 dark:border-zinc-500/50' />
					<form className='flex flex-col gap-4' onSubmit={formik.handleSubmit} noValidate>
						<div className={classNames({ 'mb-2': !formik.isValid })}>
							<Validation
								isValid={formik.isValid}
								isTouched={formik.touched.username}
								invalidFeedback={formik.errors.username}
								validFeedback='Good'>
								<FieldWrap
									firstSuffix={<Icon icon='HeroEnvelope' className='mx-2' />}>
									<Input
										dimension='lg'
										id='username'
										autoComplete='username'
										name='username'
										placeholder='Email or username'
										value={formik.values.username}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
									/>
								</FieldWrap>
							</Validation>
						</div>
						<div className={classNames({ 'mb-2': !formik.isValid })}>
							<Validation
								isValid={formik.isValid}
								isTouched={formik.touched.password}
								invalidFeedback={formik.errors.password}
								validFeedback='Good'>
								<FieldWrap
									firstSuffix={<Icon icon='HeroKey' className='mx-2' />}
									lastSuffix={
										<Icon
											className='mx-2 cursor-pointer'
											icon={passwordShowStatus ? 'HeroEyeSlash' : 'HeroEye'}
											onClick={() => {
												setPasswordShowStatus(!passwordShowStatus);
											}}
										/>
									}>
									<Input
										dimension='lg'
										type={passwordShowStatus ? 'text' : 'password'}
										autoComplete='current-password'
										id='password'
										name='password'
										placeholder='Password'
										value={formik.values.password}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
									/>
								</FieldWrap>
							</Validation>
						</div>
						<a className='flex justify-end cursor-pointer'
						onClick={() => navigate('/forgetpassword')}
						>
							Forget Password ?
						</a>
						<div>
							<Button
								size='lg'
								variant='solid'
								className='w-full font-semibold'
								onClick={() => formik.handleSubmit()}>
								Sign in
							</Button>
						</div>
						<p className='flex justify-center -mt-1'>Or</p>
						<div className='flex justify-center -mt-4'>
							<Button
							size='lg'
							className='w-full font-bold'
							onClick={()=> navigate('/signup')}
							>
							New User ? Signup Here 
							</Button>
						</div>
					</form>
				</div>
			</div>
		</PageWrapper>
	);
};

export default LoginPage;







